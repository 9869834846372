import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Modal, Checkbox, Select, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { UserContext } from "../../../hooks/context/userInfo";
import "./modalBankData.sass";
import { showNotification } from "../../../utils/notificationsGeneric";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetReward, useGetAccountType } from "src/api/api";
import { useUserInfo } from "src/api/api";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import BankAccountDataDto from "../../profile/modelResponse";
import SetBankDataDto from "../../profile/modelResponse";
import ModalCashBackCreated from "../modalCashbackCreated/modalCashbackCreated";
import ReactHtmlParser from "react-html-parser";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";
import { currencyOptions } from "src/constants/constants";



interface ParamProps {
    isModalBankDataVisible: boolean;
    setModalBankDataVisible: (value: boolean) => void;
    idReward: number;
    amount: number;
    originalCurrency: string;
    ownReward: boolean;
    bankAccount: string;
    bankAccountCountry: string
    bankAccountCurrency: string
    // idProvider: string | null;
}

const ModalBankData = (params: ParamProps) => {
    const userInfo = useUserInfo();
    const Country = (userInfo.location !== null && userInfo.location !== undefined) ? userInfo.location.isoCountryCode : userInfo.agency.location.isoCountryCode
    const [accountType, setAccountType] = useState(useGetAccountType(Country))// [IBAN, USD, SWIFT, IN, AU, MX]
    const [bankAccount, setBankAccount] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [isIban, setIsIban] = useState<boolean>(false);
    const [isUS, setIsUS] = useState<boolean>(false);
    const [isMX, setIsMX] = useState<boolean>(false);
    const [isIN, setIsIN] = useState<boolean>(false);
    const [isAU, setIsAU] = useState<boolean>(false);
    const [isSWIFT, setIsSWIFT] = useState<boolean>(false);
    const [checkBoxChecked, setCheckBoxChecked] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false); // submit button
    const [visible, setVisible] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const userContext = useContext(UserContext);
    const [form] = Form.useForm();
    const { t } = useTranslation(["modalLogin", "profile", "reward"]);
    const getReward = useGetReward();
    const restApiClient = useContext(RestApiClientContext);
    const [checkBoxTermsConditionsChecked, setCheckBoxTermsConditionsChecked] = useState(false);

    const [allCountries, setAllCountries] = useState([]);
    const [countrySelected, setCountrySelected] = useState<string>(Country);
    const [allCurrencies, setAllCurrencies] = useState([]);



    const [currencySelected, setCurrencySelected] = useState<string>(countryToCurrency[countrySelected]);


    useEffect(() => {
        setVisible(params.isModalBankDataVisible);
    }, [params.isModalBankDataVisible])


    useEffect(() => {
        switch (accountType) {
            case "IBAN":
                setIsIban(true)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "US":
                setIsIban(false)
                setIsUS(true)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "SWIFT":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(true)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "IN":
                setIsIban(true)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(true)
                setIsAU(false)
                setIsMX(false)
                break;
            case "AU":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(true)
                setIsMX(false)
                break;
            case "MX":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(true)
                break;
        }
        restApiClient.getAllCountries(userContext.GetLanguageUser()).then(v => {
            setAllCountries(v);

        });

    }, [])


    useEffect(() => {
        const fetchAccountType = async () => {
            try {
                const accountType = await restApiClient.getAccountType(countrySelected);
                setAccountType(accountType);
                updateAccountType(accountType)
            } catch (error) {
            }
        };
        fetchAccountType();
    }, [countrySelected]);

    useEffect(() => {

        const fetchProviderId = async () => {
            await restApiClient.getProviderId(userInfo.id)
                .then(v => {
                    setProviderId(v)
                })

        };

        fetchProviderId();
    }, [])



    const updateAccountType = (c: string) => {
        switch (c) {
            case "IBAN":
                setIsIban(true)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "US":
                setIsIban(false)
                setIsUS(true)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "SWIFT":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(true)
                setIsIN(false)
                setIsAU(false)
                setIsMX(false)
                break;
            case "IN":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(true)
                setIsAU(false)
                setIsMX(false)
                break;
            case "AU":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(true)
                setIsMX(false)
                break;
            case "MX":
                setIsIban(false)
                setIsUS(false)
                setIsSWIFT(false)
                setIsIN(false)
                setIsAU(false)
                setIsMX(true)
                break;
        }

    }


    const validateCheckBox = (_, value) => {
        return checkBoxTermsConditionsChecked ? Promise.resolve() : Promise.reject(t("profile:bank-account.validation-check-box"));
    };

    const handleBankAccountData = async (id) => {
        setBankAccount(await restApiClient.getBankAccount(id));
    }

    const handleCheckBoxChange = (e) => {
        setCheckBoxChecked(e.target.checked);
    };

    const handleCheckBoxTermsConditionsChange = (e) => {
        setCheckBoxTermsConditionsChecked(e.target.checked);
    };

    const handleCountryChange = (value) => {
        setCountrySelected(value);
    };

    const handleCurrencyChange = (value) => {
        setCurrencySelected(value);
    };


    // Manejador si el usuario le da a cancelar en la modal
    const handleCancel = () => {
        params.setModalBankDataVisible(false);
        setVisible(false);
    };


    const handleDontSaveBankData = async () => { // solo entra aquí si no se quiere guardar los datos bancarios
        const deleteData = async () => {
            await restApiClient.deleteBankData({ agentId: userInfo.id }).then(v => {
                if (params.bankAccount !== null) { // es decir, que antes había datos, entonces hay que reestablecerlos
                    const body = {
                        agentId: userInfo.id,
                        providerId: providerId,
                        bankAccount: params.bankAccount,
                        currency: params.bankAccountCurrency,
                        bankAccountCountry: params.bankAccountCountry
                    } as SetBankDataDto;

                    const set = restApiClient.setBankData(body).then();
                }
            })
        }

        await deleteData();

    }

    const ManageErrorGeneratingReward = (v) => {
        if (v.code === "TransactionErrorDoingTransaction") { // este error se da cuando no hay dinero por ejemplo en la cuenta de revolut, para poder diferenciarlo
            showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-provider-error-transaction"), "topRight");

        } else {
            showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-provider-error"), "topRight");
        }
        params.setModalBankDataVisible(false);
        setVisible(false)
    }

    const ManageSuccessGeneratingReward = () => {
        setLoadingUpdate(false);
        setModalSuccess(true);
        setVisible(false)
    }


    // FLUJO :
    // GUARDAR DATOS BANCARIOS 
    // HACER TRANSACCION
    // SI HAY ERROR O NO SE QUIEREN GUARDAR LOS DATOS BANCARIOS :  BORRAR DATOS BANCARIOS
    const onFinish = async (values) => {

        try {
            // Primero tenemos que mandarle los datos a rewards para guardar los datos y crear counterparty
            const body = {
                agentId: userInfo.id,
                iban: values.IBAN || null,
                accountNo: values.AccountNumber || null,
                bic: values.BIC || null,
                routingNumber: values.RoutingNumber || null,
                ifsc: values.IFSC || null,
                clabe: values.Clabe || null,
                bsbCode: values.BSBCode || null,
                bankAccountCountry: countrySelected,
                accountHolderName: values.AccountHolderName,
                accountHolderLastName: values.AccountHolderLastName,
                address: values.address,
                city: values.city,
                currency: currencySelected
            } as BankAccountDataDto;

            setLoadingUpdate(true);

            restApiClient.saveBankData(body).then(responseSaveBankData => {

                if (responseSaveBankData.code === "Success") { // tras esto, tenemos guardado en la bbdd, el nuevo bank account  
                    handleBankAccountData(userInfo.id);
                    getReward({ // hacer transacción
                        idReward: params.idReward,
                        amount: params.amount,
                        currency: params.originalCurrency,
                        ownReward: params.ownReward,
                    }).then(v => {

                        if (!checkBoxChecked) { // no guardar datos bancarios
                            handleDontSaveBankData()
                        }
                        if (v.type === "Error") {
                            ManageErrorGeneratingReward(v)
                        } else {
                            ManageSuccessGeneratingReward()
                        }

                    })

                } else if (responseSaveBankData.code == "BankDataNotEnoughData") { // error por parte del usuario en los datos dados
                    showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-bad-bank-data"), "topRight");
                    setLoadingUpdate(false)
                    params.setModalBankDataVisible(false);
                } else { // error en el proveedor
                    showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-provider-error"), "topRight");
                    setLoadingUpdate(false);
                    params.setModalBankDataVisible(false);

                }


            })

        } catch (error) {
            showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-provider-error"), "topRight");
            setLoadingUpdate(false);
            params.setModalBankDataVisible(false);
        }
    };
    return (
        <>
            <IntlProvider locale={userContext.GetLanguageUser()}>
                <Modal
                    width={'55%'}
                    title={t("profile:bank-account.modal-title")}
                    visible={visible}
                    onCancel={handleCancel}
                    footer={
                        <div className="body-modal-bank-data">
                            <div className="col-1 display-xs-reward"></div>
                            <div className="col-1 display-xs-reward"></div>
                        </div>
                    }
                    key="1"
                    className="box-modal-bank-data"
                >
                    <div className="body-modal-bank-data">
                        <div className="col-1 display-xs-reward"></div>
                        <div className="col-md-10 col-12">
                            <div className="col-12 margin-element mt-3 mb-3 padding-elements-form-reward">
                                {t("profile:bank-account.modal-description")} <strong>{params.amount} </strong>  <strong>{params.originalCurrency}  </strong>
                            </div>
                            <div className="col-12 mb-3 padding-elements-form-reward">
                            </div>
                            <Form
                                name="form-bank-account-info"
                                className="form-personal-info row"
                                onFinish={onFinish}
                                layout="vertical"
                                form={form}
                                id="form-bank-account-info-transaction"
                            >
                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.country")}
                                    name="Country"
                                    initialValue={countrySelected}
                                    required
                                /*   rules={[
                                       {
                                           validator: (_, value) => {
                                               if (!validateCountry()) {
                                                   return Promise.reject(t("profile:validations.country"));
                                               }
                                               return Promise.resolve();
                                           },
                                       },
                                   ]}*/
                                >
                                    <Select
                                        value={countrySelected}
                                        onChange={handleCountryChange}
                                        showSearch={true}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {allCountries.map(country => (
                                            <Select.Option key={country.name} value={country.code}>
                                                {country.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.currency")}
                                    name="Currency"
                                    initialValue={currencySelected}
                                    required
                                >
                                    <Select
                                        value={currencySelected} // Valor seleccionado
                                        onChange={handleCurrencyChange} // Manejador de cambio
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {currencyOptions.map((currency) => (
                                            <Select.Option key={currency.value} value={currency.value}>
                                                {currency.label}
                                            </Select.Option>
                                        ))}
                                    </Select>


                                </Form.Item>
                                <div style={{ marginTop: 0, marginLeft: '15px', marginRight: '15px', marginBottom: '15px' }}>
                                    <Alert
                                        message={ReactHtmlParser(t("profile:sections.bank-account-info.currency-description"))}
                                        type="warning"
                                        style={{ padding: '15px' }}
                                    />
                                </div>



                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.account-holder-name")}
                                    name="AccountHolderName"
                                    initialValue={userInfo.name}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile:validations.account-holder-name"),
                                        },
                                        {
                                            whitespace: true,
                                            message: t("profile:validations.account-holder-name"),
                                        },
                                    ]}
                                >
                                    <Input className="main-input" placeholder="" id="account-holder-name" />
                                </Form.Item>

                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.account-holder-last-name")}
                                    name="AccountHolderLastName"
                                    initialValue={userInfo.surname1 + " " + userInfo.surname2}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile:validations.account-holder-last-name"),
                                        },
                                        {
                                            whitespace: true,
                                            message: t("profile:validations.account-holder-last-name"),
                                        },
                                    ]}
                                >
                                    <Input className="main-input" placeholder="" id="account-holder-last-name" />
                                </Form.Item>

                                {isIban && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.iban")}
                                        name="IBAN"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.iban"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.iban"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="iban" />
                                    </Form.Item>
                                )}


                                {(isUS || isSWIFT || isIN || isAU) && (

                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.account-number")}
                                        name="AccountNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.account-number"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.account-number"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="account-number" />
                                    </Form.Item>
                                )}

                                {isMX && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.clabe")}
                                        name="Clabe"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.clabe"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.clabe"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="clabe" />
                                    </Form.Item>
                                )}

                                {(isIban || isSWIFT || isMX) && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.bic")}
                                        name="BIC"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.bic"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.bic"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="bic" />
                                    </Form.Item>
                                )}


                                {isUS && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.routing-number")}
                                        name="RoutingNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.routing-number"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.routing-number"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="routing.number" />
                                    </Form.Item>
                                )}


                                {isIN && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.ifsc")}
                                        name="IFSC"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.ifsc"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.ifsc"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="ifsc" />
                                    </Form.Item>
                                )}

                                {isAU && (
                                    <Form.Item
                                        className="col-12 form-item-profile mb-4"
                                        label={t("profile:sections.bank-account-info.bsb-code")}
                                        name="BSBCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("profile:validations.bsb-code"),
                                            },
                                            {
                                                whitespace: true,
                                                message: t("profile:validations.bsb-code"),
                                            },
                                        ]}
                                    >
                                        <Input className="main-input" placeholder="" id="bsb-code" />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.address")}
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile:validations.address"),
                                        },
                                        {
                                            whitespace: true,
                                            message: t("profile:validations.address"),
                                        },
                                    ]}
                                >
                                    <Input className="main-input" placeholder="" id="address" />
                                </Form.Item>

                                <Form.Item
                                    className="col-12 form-item-profile mb-4"
                                    label={t("profile:sections.bank-account-info.city")}
                                    name="city"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("profile:validations.city"),
                                        },
                                        {
                                            whitespace: true,
                                            message: t("profile:validations.city"),
                                        },
                                    ]}
                                >
                                    <Input className="main-input" placeholder="" id="city" />
                                </Form.Item>
                                <Form.Item
                                    className="col-12 margin-element-check "
                                    name="legal"
                                    valuePropName="check-box"
                                >
                                    <Checkbox style={{ marginTop: "5px" }}
                                        onChange={handleCheckBoxChange} >
                                        {t("profile:bank-account.modal-checkbox")}
                                    </Checkbox>
                                </Form.Item>


                                <Form.Item
                                    className="col-12 margin-element-check "
                                    name="legal"
                                    valuePropName="terms-conditions"
                                    rules={[
                                        {
                                            validator: validateCheckBox,
                                        },
                                    ]}
                                >
                                    <Checkbox
                                        id="check-policy"
                                        className="main-input box-check-reward"
                                        onChange={handleCheckBoxTermsConditionsChange}
                                    >
                                        <div className="align-text-check-terms-conditions">
                                            {ReactHtmlParser(
                                                t("profile:bank-account.check-box", { 0: t("urls:private.terms-conditions-revolut") })
                                            )}

                                        </div>
                                    </Checkbox>
                                </Form.Item>


                                <Form.Item className="col-12 margin-element mt-4">
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            id="btn-form-login"
                                            className="col-12 primary-button"
                                            type="primary"
                                            size="large"
                                            htmlType="submit"
                                            disabled={loadingUpdate || !checkBoxTermsConditionsChecked}

                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}

                                        >
                                            {loadingUpdate ? (
                                                <>
                                                    <LoadingOutlined style={{ marginRight: "3px" }} />
                                                    <span style={{ marginLeft: "3px" }}>{t("profile:bank-account.btn-do-transaction")}</span>
                                                </>
                                            ) : (
                                                t("profile:bank-account.btn-do-transaction")
                                            )}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>


                        </div>
                        <div className="col-1 display-xs-reward"></div>
                    </div>
                </Modal>
                {modalSuccess && (
                    <ModalCashBackCreated
                        isModalCashbackVisible={modalSuccess}
                        setIsModalCashbackVisible={setModalSuccess}
                        bankAccount={bankAccount}
                    />
                )}

            </IntlProvider>
        </>
    );
};
export default ModalBankData;
